import {useDisclosure} from '@mantine/hooks';
import {InvalidTokenError} from '@packages/utils';
import {parseCookies} from 'nookies';
import {useCallback, useEffect, useState} from 'react';
import {
  deleteCalendar,
  registCalendar,
} from '../../../core/repositories/calendarRepository';
import {useIsSessionExpired} from '../../../core/usecases/auth/useIsSessionExpired';

type Props = {
  scheduleId: number;
  isRegisteredInital: boolean;
  registeredCountInitial: number;
};

export const useHandleCalendar = ({
  scheduleId,
  isRegisteredInital,
  registeredCountInitial,
}: Props) => {
  const [registeredCount, setRegisteredCount] = useState<number>(
    registeredCountInitial,
  );
  const [isRegistered, setRegistered] = useState<boolean>(isRegisteredInital);
  const [
    notificationOpened,
    {open: notificationOpen, close: notificationClose},
  ] = useDisclosure(false);
  const {updateIsSessionExpired} = useIsSessionExpired();

  useEffect(() => {
    setRegisteredCount(registeredCountInitial);
    setRegistered(isRegisteredInital);
  }, [scheduleId]);

  // 通知は2秒後に消える
  useEffect(() => {
    if (notificationOpened) {
      const timer = setTimeout(() => {
        notificationClose();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [notificationOpened]);

  const registCalendarUsecase = async () => {
    const cookies = parseCookies();
    const result = await registCalendar({
      cookies,
      scheduleId,
    });
    if (!result.ok && result.error === InvalidTokenError) {
      updateIsSessionExpired(true);
    }

    if (!result.ok) {
      return;
    }

    setRegistered(true);
    notificationOpen();
    setRegisteredCount(registeredCount + 1);
  };

  const deleteCalendarUsecase = async () => {
    const cookies = parseCookies();
    const result = await deleteCalendar({
      cookies,
      scheduleId,
    });
    if (!result.ok && result.error === InvalidTokenError) {
      updateIsSessionExpired(true);
    }

    if (!result.ok) {
      return;
    }

    setRegistered(false);
    setRegisteredCount(registeredCount - 1);
  };

  const handleCalendar = useCallback(async () => {
    if (isRegistered) {
      return deleteCalendarUsecase();
    }

    return registCalendarUsecase();
  }, [isRegistered]);

  return {
    isRegistered,
    registeredCount,
    handleCalendar,
    notificationOpened,
  };
};
