import type {
  RecruitmentTag,
  RecruitmentType,
} from '../../../core/entities/recruitment/schema';

const COMMON_CLASS_NAME =
  'text-xs text-white py-2 px-4 min-w-[72px] text-center';

export const RecruitmentTypeListItem: React.FC<{
  recruitmentType: RecruitmentType;
}> = ({recruitmentType}) => {
  let bgClassName = 'bg-black-3';
  switch (recruitmentType.key) {
    case 'selection': {
      bgClassName = 'bg-category-1';
      break;
    }

    case 'intern': {
      bgClassName = 'bg-category-7';
      break;
    }

    case 'event':
    case 'jointEvent':
    case 'gssktEvent': {
      bgClassName = 'bg-category-3';
      break;
    }

    default: {
      break;
    }
  }

  return (
    <p className={`${bgClassName} ${COMMON_CLASS_NAME}`}>
      {recruitmentType.label}
    </p>
  );
};

export const RecruitmentTargetYearTag: React.FC<{
  label: string;
}> = ({label}) => {
  return <p className={`bg-black-2 ${COMMON_CLASS_NAME}`}>{label}</p>;
};

export const RecruitmentJobTypeTag: React.FC<{
  jobType: {id: number; name: string};
}> = ({jobType}) => {
  return (
    <p
      key={`RecruitmentJobTypeTag-${jobType.id}`}
      className={`bg-black-3 ${COMMON_CLASS_NAME}`}
    >
      {jobType.name}
    </p>
  );
};

export const RecruitmentTagListItem: React.FC<{
  recruitmentTag: RecruitmentTag;
}> = ({recruitmentTag}) => {
  return (
    <p
      key={`recruitmentTag-${recruitmentTag.id}`}
      className={`bg-black-3 ${COMMON_CLASS_NAME}`}
    >
      {recruitmentTag.shortName}
    </p>
  );
};
