import {parse} from 'node:path';
import {type AppError, InvalidTokenError, type Result} from '@packages/utils';
import {
  type UseInfiniteQueryResult,
  type UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import {addDays, getUnixTime} from 'date-fns';
import {parseCookies} from 'nookies';
import type {z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  DeleteCalendarSchema,
  DeleteMyScheduleSchema,
  GetMyCalendarSchema,
  GetMyScheduleSchema,
  GetMyScheduleStatusSchema,
  PostScheduleSyncExecSchema,
  PutScheduleSyncStopSchema,
  RegistCalendarSchema,
} from '../entities/calendar/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

export const registCalendar = async ({
  cookies,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: {
  cookies: Partial<Record<string, string>>;
  scheduleId: number;
  version?: string;
}) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.post(
      `/${version}/recruitment-schedules/${scheduleId}/calendar`,
      RegistCalendarSchema,
      {},
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export const deleteCalendar = async ({
  cookies,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: {
  cookies: Partial<Record<string, string>>;
  scheduleId: number;
  version?: string;
}) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.delete(
      `/${version}/recruitment-schedules/${scheduleId}/calendar`,
      DeleteCalendarSchema,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

/**
 * GetMeRecruitmentSchedules
 */
export type GetMyCalendarSchema = z.infer<typeof GetMyCalendarSchema>;
type ResultGetMyCalendarSchema = Result<GetMyCalendarSchema, AppError>;

type UseGetCalendarOptions = {
  config?: UseQueryOptions<ResultGetMyCalendarSchema>;
  version?: string;
};

export const useGetCalendar = ({config}: UseGetCalendarOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetMyCalendarSchema>({
    ...config,
    queryKey: ['getMyCalendar'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getMyRecruitmentSchedules({
        cookies,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export const getMyRecruitmentSchedules = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: {
  cookies: Partial<Record<string, string>>;
  version?: string;
}) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const path = createPathWithQuery(
      `/${version}/users/me/right-side-schedule`,
      {
        after: getUnixTime(addDays(new Date(), 60)),
      },
    );
    return defaultClient.get(path, GetMyCalendarSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};

type GetMyScheduleArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
  queryParams: {
    limit: number;
    page: number;
  };
};

export type GetMyScheduleSchema = z.infer<typeof GetMyScheduleSchema>;
type ResultGetMyScheduleSchema = Result<GetMyScheduleSchema, AppError>;

type UseGetScheduleOptions = {
  config?: UseQueryOptions<ResultGetMyScheduleSchema>;
} & Omit<GetMyScheduleArgs, 'cookies'>;

export const getMySchedules = async ({
  version = DEFAULT_API_VERSION,
  cookies,
  queryParams,
}: GetMyScheduleArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(
      `/${version}/users/me/schedule`,
      {...queryParams},
      'none',
    );
    return defaultClient.get(path, GetMyScheduleSchema);
  };

  const result = await sendApiRequest<GetMyScheduleSchema>({
    request,
    cookies,
    version,
  });
  return result;
};

export const useInfiniteSchedule = ({
  version = DEFAULT_API_VERSION,
  queryParams,
}: UseGetScheduleOptions): UseInfiniteQueryResult<
  GetMyScheduleSchema,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useInfiniteQuery(
    ['MySchedule', queryParams],
    async ({pageParam = {page: 1}}) => {
      const cookies = parseCookies();
      const result = await getMySchedules({
        cookies,
        version,
        queryParams: {
          ...queryParams,
          page: pageParam.page,
        },
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result.ok ? result.value : undefined;
    },
    {
      getNextPageParam(lastPage) {
        return lastPage && lastPage?.page > 0
          ? {page: lastPage.page}
          : undefined;
      },
    },
  );
};

type GetMyScheduleStatusArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
};

export type GetMyScheduleStatusSchema = z.infer<
  typeof GetMyScheduleStatusSchema
>;
type ResultGetMyScheduleStatusSchema = Result<
  GetMyScheduleStatusSchema,
  AppError
>;

type UseGetScheduleStatusOptions = {
  config?: UseQueryOptions<ResultGetMyScheduleStatusSchema>;
  version?: string;
};

export const getMyScheduleStatus = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: GetMyScheduleStatusArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(
      `/${version}/users/me/schedule/sync/status`,
      {},
      'none',
    );
    return defaultClient.get(path, GetMyScheduleStatusSchema);
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export const useGetMyScheduleStatus = ({
  version,
  config,
}: UseGetScheduleStatusOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetMyScheduleStatusSchema>({
    ...config,
    queryKey: ['getMyScheduleStatus'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getMyScheduleStatus({
        cookies,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type PostScheduleSyncExecResponse = z.infer<
  typeof PostScheduleSyncExecSchema
>;
export type PostScheduleSyncExecReturnValueType =
  Result<PostScheduleSyncExecResponse>;

type PostScheduleSyncExecArgs = {
  cookies: Partial<Record<string, string>>;
  googleAccessToken: string;
  googleRefreshToken: string;
  expiry: string;
  gmail: string;
  version?: string;
};

export const postScheduleSyncExec = async ({
  cookies,
  googleAccessToken,
  googleRefreshToken,
  expiry,
  gmail,
  version = DEFAULT_API_VERSION,
}: PostScheduleSyncExecArgs) => {
  const request = async (accessToken: string) => {
    defaultClient.setGoogleCalendarToken({
      accessToken,
      googleAccessToken,
      googleRefreshToken,
      expiry,
    });
    return defaultClient.post(
      `/${version}/users/me/schedule/sync/exec`,
      PostScheduleSyncExecSchema,
      {gmail},
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export type PutScheduleSyncStopResponse = z.infer<
  typeof PutScheduleSyncStopSchema
>;
export type PutScheduleSyncStopReturnValueType = Result<
  PutScheduleSyncStopResponse,
  AppError
>;

type PutScheduleSyncStopArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const putScheduleSyncStop = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: PutScheduleSyncStopArgs): Promise<PutScheduleSyncStopReturnValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.put(
      `/${version}/users/me/schedule/sync/stop`,
      PutScheduleSyncStopSchema,
      {},
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });
  return result;
};

export type DeleteMyScheduleResponse = z.infer<typeof DeleteMyScheduleSchema>;
export type DeleteMyScheduleReturnValueType = Result<
  DeleteMyScheduleResponse,
  AppError
>;

type DeleteMyScheduleArgs = {
  cookies: Partial<Record<string, string>>;
  scheduleId: number;
  version?: string;
};

export const deleteMySchedule = async ({
  cookies,
  scheduleId,
  version = DEFAULT_API_VERSION,
}: DeleteMyScheduleArgs): Promise<DeleteMyScheduleReturnValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.delete(
      `/${version}/users/me/schedule/event/${scheduleId}/delete`,
      DeleteMyScheduleSchema,
    );
  };

  const result = await sendApiRequest({
    request,
    cookies,
    version,
  });

  return result;
};
